.avatarBackground {
  background-color: white;
}

.cardImageWrapperOverride {
  background-color: transparent;
}

.cardImageOverride {
  object-fit: cover;
  background-color: transparent;
}
